import React from "react";
import BBB from "../../images/BBB-1.svg";
import QB from "../../images/QB.svg";
import DEXT from "../../images/Dext-2.svg";
import { StaticImage } from "gatsby-plugin-image";
import Plooto from "../../images/plooto.svg";
import Link from "gatsby-link";
import Video from "../../components/Video/Video";
import QB2 from "../../images/qb2.svg";
export default function Example() {
  return (
    <main>
      <div>
        {/* Hero card */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div
                className="absolute inset-0 shadow-xl overflow-hidden sm:overflow-hidden aspect-w-5 md:aspect-h-0 "
                style={{
                  borderRadius: "5px",
                  webkitBorderRadius: "8px",
                  mozBorderRadius: "5px",
                  khtmlBorderRadius: "5px",
                  overflow: "hidden",
                  border: "3px transparent",
                  webkitMaskImage: "webkit-radial-gradient(white, black)",
                }}
              >
                <StaticImage
                  src="../../images/test-10.jpg"
                  alt="outsource-bookkeeping"
                  placeholder="fixed"
                  layout="fullWidth"
                  imgStyle={{ overflow: "hidden" }}
                  imgClassName="rounded-lg"
                  style={{ overflow: "hidden" }}
                  border="5px"
                  // You can optionally force an aspect ratio for the generated image
                  aspectRatio={1 / 1}
                  // This is a presentational image, so the alt should be an empty string
                  // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                  formats={["auto", "webp", "avif"]}
                />
                <div
                  className="absolute inset-0 bg-gray-500 mix-blend-multiply sm:flex-shrink-0"
                  style={{
                    borderRadius: "5px",
                    webkitBorderRadius: "8px",
                    mozBorderRadius: "5px",
                    khtmlBorderRadius: "5px",
                    overflow: "hidden",
                  }}
                />
              </div>
              <div className="relative px-4 py-12 sm:px-6 sm:py-24  lg:py-32 lg:px-8 xs:pt-24">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Certus Bookkeeping</span>
                  <span className="block text-title">Outsourced Bookkeeping</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                 Outsource your bookkeeping to our team of professional bookkeepers.
                </p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center ">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid ">
                    <Link
                      to="/contact-us"
                      href="/contact-us"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-secondary bg-white hover:bg-indigo-50 sm:px-8"
                    >
                      Connect With Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logo cloud */}
            <div className="bg-gray-100 content-center place-items-center ">
          <div className="max-w-7xl mx-auto  xs:py-28 px-4 sm:px-6 content-center place-items-center ">
            <p className="text-center text-md font-semibold uppercase text-gray-500 tracking-wide py-4 lg:-top-5 -top-20 relative">
              Trusted By
            </p>
            <div className="mt-3 grid grid-cols-2 xs:grid-col-1 gap-8 md:grid-cols-6 lg:grid-cols-5 content-center"></div>
            <div className="h-32 flex   gap-x-10  flex-wrap content-center items-center self-center place-items-center justify-center">
              <div>
                <a href="https://proadvisor.intuit.com/app/accountant/search?searchId=zachary-smith47">
                  <QB className="h-28  fill-current text-gray-600 self-center " />
                </a>
              </div>

              <div>
                <a href="https://proadvisor.intuit.com/app/accountant/search?searchId=zachary-smith47">
                  <QB2
                    className="h-28  fill-current  text-gray-600 self-center "
                    width="100"
                  />
                </a>
              </div>
              <div>
                <a href="https://www.bbb.org/ca/on/east-york/profile/bookkeeping-services/certus-0107-1383681">
                  <BBB className="h-28 fill-current  text-green-600 self-center " />
                </a>
              </div>
              <div>
                <DEXT className="h-24 xs:py-5 fill-current  text-green-600 self-center " />
              </div>
              <div>
                <Plooto className="h-24 xs:py-5 fill-current  text-green-600 self-center " />
              </div>
            </div>
          </div>
        </div>

        <div>
          <Video />
        </div>
      </div>
    </main>
  );
}
