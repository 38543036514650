import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>Outsource Bookkeeping | Certus Bookkeeping</title>
      <meta name="description" content="We are the leading provider of outsource bookkeeping services in Canada. Connect with our team today to get started on your outsourcing bookkeeping needs. " />
      <link rel="canonical" href="https://certusbookkeeping.com/outsource-bookkeeping" />
    </Helmet>
  );
}
