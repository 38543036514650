/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet";
const faqs = [
  {
    question: "Why should I outsource bookkeeping for my small business?",
    answer:
      "When you outsource your bookkeeping to a high end bookkeeping provider, it will ensure that your business is financially optimized. A high end bookkeeping provider will put in place an infrastructure that will allow for workflows to be streamlined and efficient. They will also keep your company in compliance with both federal and provincial tax laws. A high end provider will also facilitate sales tax and payroll services.",
  },
  {
    question: "What is outsourced accounting?",
    answer:
      "Outsourced accounting is when a business hires a fully trained bookkeeping provider to complete bookkeeping operations. Most of the time, when outsourcing these duties, the work is performed from a remote location.",
  },
  {
    question: "How can I manage payroll?",
    answer:
      "The best way to manage payroll as well as increase accuracy and efficiency, is to hire a high end payroll provider",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
            "name": "Why should I outsource bookkeeping for my small business?",
            "acceptedAnswer": {
              "@type": "Answer",
            "text": "<p>When you outsource your bookkeeping to a high end bookkeeping provider, it will ensure that your business is financially optimized. A high end bookkeeping provider will put in place an infrastructure that will allow for workflows to be streamlined and efficient. They will also keep your company in compliance with both federal and provincial tax laws. A high end provider will also facilitate sales tax and payroll services.</p>"
                  }
                }, {
            "@type": "Question",
              "name": "What is outsourced accounting?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>Outsourced accounting is when a business hires a fully trained bookkeeping provider to complete bookkeeping operations. Most of the time, when outsourcing these duties, the work is performed from a remote location.<p>"
             }
           },{
            "@type": "Question",
              "name": "How can I manage payroll?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>The best way to manage payroll as well as increase accuracy and efficiency, is to hire a high end payroll provider.<p>"
             } 
          }]
              }`}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
