import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CTA from "./CTA";
import Link from "gatsby-link";
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Outsourced Bookkeeping
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Outsourcing your bookkeeping needs to a third party is a significant
            step for any business. It will undoubtedly increase savings and free
            up time to complete work that is crucial to the business. That being
            said, finding the right provider of bookkeeping services can be
            stressful.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus Bookkeeping is a leading provider of bookkeeping services for
            both individuals and businesses. We are a team that understands the
            struggles that come with keeping your books in order. It is
            detail-oriented work that requires a great deal of time and
            preparation. This is why it is a massive benefit to have
            professional bookkeepers handle this for you. The Certus team has
            years of experience in providing accounting and bookkeeping services
            to individuals and businesses throughout the country.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Why Outsourced Bookkeeping?</h2>
          <p>
            It may sound obvious, but running your own business is a demanding
            pursuit. It requires time, organization, discipline, and dedication.
            There are many risks involved with starting a business, especially
            financial risks. One of the biggest threats to the success of any
            business is financial disorganization. This commonly comes as a
            result of taking on more than one individual can handle causing
            crucial tasks to be pushed to the side.
          </p>
          <p>
            This is why the team at Certus always recommends small business
            owners hire a professional bookkeeper. Doing so will ensure that
            small but devastating mistakes are prevented. Not only will these
            common mistakes be prevented, but you will free up significant time
            by handing these responsibilities off to an experienced bookkeeper.
            You will no longer have to manage your QuickBooks Online, prepare
            financial data, process payroll, or file your tax returns. The
            increased free time and financial organization will undeniably
            improve your business and work-life balance.
          </p>
        </div>
        <CTA />
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Why Choose Certus?</h2>
          <p>
            Hiring Certus as your provider of outsourced bookkeeping services
            comes with a multitude of benefits. We will work closely with your
            business to make sure that you are receiving the highest level of
            professionalism available. We have years of experience providing
            premium quality bookkeeping services to business and individuals. As
            a Certus client, both individuals and businesses will have access to
            services such as expense tracking, receipt digitization & filing and
            workflow management.
          </p>
          <p>
            Our team is confident in our ability to get your business’s finances
            in order. Our services will ensure timely tax preparation, allowing
            our clients to achieve a healthy work-life balance. All we need to
            get started is a short consultation to get to know your business.
          </p>
          <p>
            After the consultation, our team of financial experts will use the
            information gathered to create a cost-effective solution that is
            tailored to your needs. We will analyze your workflows and provide
            you with a detailed process that will ensure that no stone is left
            unturned. You will then be provided with a secure cloud-based system
            that allows easy access to your financial data. During tax season,
            you will be able to access everything you need in one location.
          </p>
          <p>
            Choosing Certus as your provider of professional bookkeeping
            services will ensure that you are well looked after. We will bring
            you peace of mind by fully demonstrating the benefits of outsourcing
            your bookkeeping. You will be able to rest easy knowing your
            finances are being managed full time by a team of skilled
            professionals. It is our guarantee that we have the knowledge,
            experience, and ability to handle all of your bookkeeping needs.
          </p>
          <h2 style={{ marginTop: "0px" }}> Get Started</h2>
          <p>
            Interested in receiving a quote? Visit the{" "}
            <Link
              to="/contact-us"
              style={{
                textDecoration: "none",
                color: "#6B7280",
                "&:hover": {
                  background: "#efefef",
                },
                ":visited": {
                  color: "purple",
                },
              }}
            >
              contact us{" "}
            </Link>{" "}
            page on our website to schedule a consultation with one of our
            financial experts. We promise that after meeting with our team, you
            will know that Certus is the top choice for professional bookkeeping
            services.
          </p>
        </div>
      </div>
    </div>
  );
}
